@import "./tailwind-preset.css";
@import "./fh-oscar";
@import "./variables";
@import "./animations";
@import "./scroll-animations";
@import "./inputs";
@import "./spinner";
@import "./swiping-cards";
@import "./front-pages";
@import "./utilities";
@import "./_store-front-colors-config";

* {
  -webkit-overflow-scrolling: auto;
}

body {
  margin: 0;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-display {
  font-family: "FH Oscar", "Inter", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  letter-spacing: 0.5px !important;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

//scrolling images
.scrolling-image {
  width: 100%;
  padding-top: 120%;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s all;
  transform: scale(1);
  will-change: transform;
  &:hover {
    transform: scale(1.05);
    &::after {
      opacity: 1;
    }
  }
  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::after {
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.6);
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.3s ease-out opacity;
  }
}

//auth display images
.auth-display-image {
  figure {
    padding-top: 110%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.image-picker {
  position: relative;
  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: dashed 1px #d8d8d8;
    border-radius: 10px;
    top: 10%;
  }
  &::after {
    left: -50%;
    transform: rotate(-15deg);
  }
  &::before {
    left: 50%;
    transform: rotate(15deg);
  }
}

.app-table {
  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 15px;
    }
    td:last-child {
      border-bottom-right-radius: 15px;
    }
  }
}

.store-hero-image {
  padding-top: 35%;

  @include sm {
    padding-top: 30%;
  }
  @include md {
    padding-top: 25%;
  }
  @include lg {
    padding-top: 20%;
  }
  background: linear-gradient(272.94deg, rgba(242, 240, 249, 0.6) -4.11%, rgba(250, 250, 252, 0.6) 100%);
}

.store-front-hero {
  height: 28vw;

  @include md {
    height: 22vw;
  }
  @include lg {
    height: 16vw;
  }
  background-size: cover;
  background-position: center;
  background-color: #f8f5f5;
}

.nav-toggle {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
  position: relative;
  transition: 0.3s ease-out all;
  &-inner {
    display: inline-block;
    height: 1.5px;
    width: 100%;
    background: #000;
    border-radius: 10px;
    position: relative;
    transition: 0.3s ease-out all;
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: -6px;
      left: 0;
      height: 1.5px;
      width: 100%;
      background: #000;
      transition: 0.3s ease-out all;
    }
    &::after {
      bottom: -6px;
      top: unset;
    }
    &:hover {
      background: #332098 !important;
      &::before,
      &::after {
        background: #332098 !important;
      }
    }
  }
}

.landing-header {
  position: relative;
  .header-bg {
    position: absolute;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
    background: linear-gradient(139.06deg, #332098 0%, #332098 10%, #5943dd 100%);
    height: 90%;
    width: 100%;
    left: 0;
  }
}

.cta-image {
  background: url("/images/shopper.jpeg");
  background-size: cover;
}

.timeline {
  position: relative;
  &-item {
    // padding-left: 45px;
    position: relative;
    &__indicator {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      border: 2px solid rgba(#72cf90, 0.67);
      margin-right: 10px;
      margin-top: -3px;
      position: relative;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      &::before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        background: rgba(#72cf90, 0.67);
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: calc(100% - 30px);
    background: rgba(#72cf90, 0.67);
    left: 10px;
    transform: translateX(-50%);
  }
}

.typed-cursor {
  font-weight: 400 !important;
  font-size: 0.5em;
  display: inline-block;
  transform: translateY(-20%) scale(1, 2.5);
  position: relative;
  z-index: 10;
}

.typed-cursor {
  font-weight: 400 !important;
  font-size: 0.5em;
  display: inline-block;
  transform: translateY(-20%) scale(1, 2.5);
  position: relative;
  z-index: 10;
}

.order-item:not(:last-child) {
  position: relative;
  //   width: 100% !important;

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    width: calc(100% - 40px);
    background: #f0f0f0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @include sm {
      width: calc(100% - 50px);
    }
  }
}

.order-login {
  background: url("/images/patterns/purple-pattern.svg");
  background-size: cover;
}

.product-images-grid {
  --cols: 5;
  display: grid;
  grid-template-columns: 10px repeat(var(--cols), 35%) 10px;

  @include sm {
    grid-template-columns: 10px repeat(var(--cols), 30%) 10px;
  }
}

.safari-overflow-fix {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.elipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.elipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.restaurant-cart {
  filter: drop-shadow(0px 5px 45px rgba(51, 32, 152, 0.15));
}

// .clg-app-btn {
//   &:hover {
//     filter: saturate(1.25);
//   }

//   &:disabled {
//     filter: brightness(1.25);
//   }
// }

.overflow-x-auto {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Prevent scrollbar space from being reserved in IE, Edge, and Firefox */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.val-gift-page {
  background: url("/images/patterns/light-red-pattern.svg");
  background-size: cover;
}
